import PropTypes from "prop-types"
import { Link as IntlLink, FormattedMessage } from "gatsby-plugin-intl"
import React from "react"

import './header.css';

const Header = ({ siteTitle }) => (
  <header className="nav">
    <div className="inner" >
      <h1>
        <IntlLink to="/">
          {siteTitle}
        </IntlLink>
      </h1>
      <IntlLink to="/portfolio">
        <FormattedMessage id="nav-portfolio" />
      </IntlLink>
      <IntlLink to="/contact">
        <FormattedMessage id="nav-contact" />
      </IntlLink>
      {/*
      <IntlLink to="/about">
        <FormattedMessage id="nav-about" />
      </IntlLink>
      */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
