import React from "react";
import {FormattedMessage} from "gatsby-plugin-intl";
import './hero.css';

const Hero = ({image, tagline, image_attribution = {}}) => {
  const src = typeof image === "string" ? image : image.childImageSharp.fluid.src;
  return (
    <section className="hero" style={{backgroundImage: `url(${src})`}}>
      {tagline &&
        <div className="hero-shadow">
          <div className="hero-text">
            <h1>{tagline}</h1>
          </div>
        </div>
      }
      {image_attribution &&
        <div className="attribution" >
          <FormattedMessage
            id="hero-attribution"
            defaultMessage="Photo by <a>{name}</a> on <unsplash_link>Unsplash</unsplash_link>"
            values={{
              name: image_attribution.name,
              a: (...chunks) => (
                <a
                  href={`https://unsplash.com/@${image_attribution.user}`}
                  target="_blank" rel="noopener noreferrer"
                >
                {chunks}
                </a>
              ),
              unsplash_link: (...chunks) => (
                <a
                  href="https://unsplash.com/"
                  target="_blank" rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              )
            }}
          />
        </div>
      }
    </section>

  );
}

export default Hero;
